<template>
  <section class="all-documents-view">
    <el-row type="flex" justify="center" align="center" :gutter="30">
      <el-dialog
        :visible.sync="documentActionModal"
        width="30%"
        class="doc-modal"
        v-loading="modalLoading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <span slot="footer" class="dialog-footer">
          <el-button @click="documentActionModal = false">Cancel</el-button>
          <el-button type="primary">Confirm</el-button>
        </span>
      </el-dialog>
      <el-col :span="10">
        <div class="mb-4 d-flex justify-content-between align-items-center">
          <h3
            class="title fw-normal text-dark mb-0 ls-05"
          >
            <a href="/contacts" v-if="checkisCompanyUser('contact-types')">
              <i class="el-icon-arrow-left"></i>
            </a>Contact
            Types
          </h3>
        </div>
        <div v-loading="loading" class="vue-data-table-default">
          <data-tables-server
            v-loading="configureLoading"
            :fullscreen="true"
            :data="data"
            :total="total"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            :pagination-props="paginationProps"
            @query-change="loadData"
            style="width: 100%"
          >
            <el-table-column label="Name" id="name" min-width="250" fixed="left" prop="name"></el-table-column>
            <el-table-column prop="action" label="Actions" class="action" width="120">
              <template slot-scope="scope">
                <ul class="action-buttons" v-if="checkStandardEntity(scope.row)">
                  <li
                    v-if="
                      checkPermissions('editContactType') ||
                      getAuthenticatedUser._id ==
                        scope.row.user
                    "
                  >
                    <el-button
                      plain
                      type="warning"
                      size="mini"
                      class="px-2"
                      @click="onEdit(scope.row)"
                    >
                      <i class="el-icon-edit"></i>
                    </el-button>
                  </li>
                  <li
                    v-if="
                      checkPermissions('deleteContactType') ||
                      getAuthenticatedUser._id ==
                        scope.row.user
                    "
                  >
                    <template
                      v-if="
                        (getAuthenticatedUser._id &&
                          scope.row.user &&
                          getAuthenticatedUser._id ==
                            scope.row.user) ||
                        getActiveWorkspace.company_id ==
                          scope.row.company_id || this.isIndividualWorkspace(this.getActiveWorkspace)
                      "
                    >
                      <el-button
                        plain
                        type="danger"
                        size="mini"
                        class="px-2"
                        @click="onDelete(scope.row._id)"
                      >
                        <i class="el-icon-delete"></i>
                      </el-button>
                    </template>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </data-tables-server>
        </div>
      </el-col>
      <el-col :span="10" v-if="checkPermissions('createContactType')">
        <AddContactTypes
          @newContactTypeAdded="fetchContacts"
          :selectedContactType="selectedContactType"
          @clearSelectedContactType="selectedContactType = ''"
          @update-success="handleUpdateSuccess"
        ></AddContactTypes>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
const AddContactTypes = () => import("./AddContactTypes.vue");

import PermissionsHelper from "@/mixins/permissionsHelper";
export default {
  name: "contacts-ContactTypes",
  props: ["pageSizes", "type", "categoryId", "groupId"],
  components: { AddContactTypes },

  mixins: [PermissionsHelper],
  data() {
    return {
      modalLoading: false,
      documentActionModal: false,
      loading: false,
      selectedContactType: "",
      total: 0,
      currentPage: 1,
      pageSize: 0,
      data: [],
      configureLoading: false
    };
  },
  computed: {
    ...mapGetters("contactTypes", [
      "getAllContactTypes",
      "getContactTypeDeleteStatus",
      "getContactTypeErrors"
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getUserType"
    ]),
    ...mapGetters("entities", [
      "getEntityDeleteStatus",
      "getEntityDeleteError"
    ]),
    ...mapGetters("errors", ["getError"]),
    ...mapGetters("menuManagementV2", ["getMenu"]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 25, 50]
      };
    },
    checkisCompanyUser() { 
      return value => {
        if (this.getMenu?.menu_list) {
          let index = this.getMenu.menu_list.findIndex( 
            e => e.route_id == value
          );
          if(index > -1){
            return false 
          }
        }
        return true;
      };
    }
  },
  async mounted() {
    await this.fetchContacts();
  },
  methods: {
    checkStandardEntity(data) {
      return data.type != "STANDARD" && data.slug != "receiver";
    },
    async fetchContacts() {
      try {
        this.loading = true;
        let params = {
          limit: this.pageSize > 0 ? this.pageSize : 10,
          page: this.currentPage
        };
        await this.$store.dispatch("contactTypes/fetchContactTypes", params);
        this.data = this.getAllContactTypes.data;
        this.total = this.getAllContactTypes.total;
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    onEdit(contactType) {
      this.selectedContactType = contactType;
    },
    handleUpdateSuccess() {
      this.selectedContactType = null;
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.currentPage = data.page;
        await this.fetchContacts();
      }
    },

    async onDelete(id) {
      this.$confirm("Are you sure to delete the contact type?")
        .then(async () => {
          this.loading = true;
          try {
            let params = {
              id: id
            };
            await this.$store.dispatch("entities/deleteEntity", params);
            await this.fetchContacts();
            if (this.getEntityDeleteStatus) {
              this.$notify.success({
                title: "Success",
                message: "Entity deleted successfully"
              });
            } else {
              this.$notify.error({
                title: "Error",
                message: this.getEntityDeleteError || "Error on deleting Entity"
              });
            }

            // await this.$store.dispatch("contactTypes/delete", id);
            // if (this.getContactTypeDeleteStatus) {
            //   this.successNotify("Contact type deleted successfully");
            //   await this.fetchContacts();
            // } else if (
            //   this.getContactTypeErrors &&
            //   this.getContactTypeErrors.critical_error
            // ) {
            //   this.errorNotify(this.getContactTypeErrors.critical_error);
            // } else {
            //   this.errorNotify(
            //     "Some contacts are associated with this contact type you can't perform delete action!!!"
            //   );
            // }
          } catch (err) {
            console.log(err);
          }
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    checkPermissions(permission) {
      if (this.getUserType && this.getUserType.permissionSet) {
        return this.getUserType.permissionSet.includes(permission)
          ? true
          : false;
      } else {
        return true;
      }
    }
  }
  // beforeDestroy() {
  //   //getContactDeleteStatus
  //   this.$store.commit("contactTypes/setContactTypeDeleteStatus", null,{root:true})
  //   this.$store.commit("contactTypes/setContactTypeErrors", null,{root:true})
  //   this.$store.commit("entities/setEntityDeleteStatus", null,{root:true})
  //   this.$store.commit("entities/setEntityDeleteError", null,{root:true})
  //  this.$store.commit( "contactTypes/setAllContactTypes", null,
  //    {
  //     root: true,
  //   }
  //   );
  // },
};
</script>

<style lang="scss" scoped>
.all-documents-view {
  .title {
    letter-spacing: 0.019em;
    margin-left: -1.45em;
  }
  // .icon-block {
  .initial {
    grid-template-columns: 35px auto;
    .icon {
      border: 1px solid #ffa9a9;
      text-align: center;
      border-radius: 30px;
      padding: 5px;
      background-color: #f1494910;
      .initial-text {
        font-size: 0.8em;
        span {
          color: #f14949;
        }
      }
    }
  }
  // }
  .circle {
    position: relative;
    padding-left: 20px;
    text-transform: capitalize;
    font-size: 14px;
    &::after {
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      height: 10px;
      width: 10px;
      display: inline-block;
      border-radius: 50%;
    }
    &.completed::after {
      background: #08ad36;
    }
    &.draft::after {
      background: #c4c4c4;
    }
    &.viewed::after {
      background: #2f80ed;
    }
  }
}
</style>
